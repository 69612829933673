import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore'
import { getMessaging, onMessage } from "firebase/messaging";
import { getStorage, ref } from "firebase/storage";

const app = initializeApp({
    apiKey: import.meta.env.VITE_API_KEY,
    authDomain: import.meta.env.VITE_AUTH_DOMAIN,
    projectId: import.meta.env.VITE_PROJECT_ID,
    storageBucket: import.meta.env.VITE_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.VITE_MESSAGING_SENDER_ID,
    appId: import.meta.env.VITE_APP_ID,
    measurementId: import.meta.env.VITE_MEASUREMENT_ID,
    storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_DEFAULT_BUCKET
});

const messaging = getMessaging(app);
const db = getFirestore(app)

if ("serviceWorker" in navigator) {
    navigator.serviceWorker.register("/firebase-messaging-sw.js").then(function (registration) {
        registration.onupdatefound = function () {
            const installingWorker = registration.installing;
            installingWorker.onstatechange = function () {
                if (installingWorker.state === 'installed') {
                    if (navigator.serviceWorker.controller) {
                        console.log('New or updated content is available.');
                        window.location.reload();
                    } else {
                        console.log('Content is now available offline!');
                    }
                }
            };
        };
    }).catch(function (err) {
        console.error("Service Worker registration failed: ", err);
    });
}

onMessage(messaging, (payload) => {
    if ("Notification" in window) {
        Notification.requestPermission().then((permission) => {
            if (permission === "granted") {
                var notification = new Notification(payload.data.title, {
                    body: payload.data.body,
                    image: payload.data.image,
                    icon: payload.data.icon || '/app-assets/images/logo.png',
                    requireInteraction: false,
                    silent: false,
                    vibrate: [200, 100, 200, 100, 200, 100, 200],
                });
                notification.onclick = function() {
                    window.open(payload.data.route);
                };
            }
        });
    }
});

const storage = getStorage();

export default {messaging, db, storage, ref}